import React from "react";
import img from "./Group 12197.png";
import "./styles.scss";

function Banner(props) {
  return (
    <section className="hero">
      <div className="container-hero">
        <div className="row">
          <div className="content">
            <div className="title">
              <h2>
                Digital Marketing
                <span className="glossary"> Glossary</span>
              </h2>
            </div>
            <div className="details">
              <p>
              Confused about soo many new terms and jargons you’ve been reading
              about and learning? Worry not.
              </p>
              <br />
              <p>
              You can now find the detailed definitions of all the terms you’ll
              be using on a regular basis now, right here in this Digital
              Marketing Glossary.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="items imgBook">
            <img src={img} alt="img" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
