import React from "react";
// import Layout from "../../components/Layout/layout";
import Header from "../../components/Layout/BlogLayout/Header";
import Footer from "../../components/Layout/BlogLayout/Footer";
import "./style.scss";
import { graphql } from "gatsby";
import { useState } from "react";
//  import { RouteComponentProps, withRouter } from '@reach/router';
import Banner from "../../components/GlossaryCard/Banner";
import GlossariesList from "./GlossariesList";
import queryString from "query-string";
import { useEffect } from "react";

const Glossaries = (props) => {
  let { data, location, Children } = props;

  let params = queryString.parse(props.location.search);
  let currentCharacer = params.alphabet;

  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const [selected, setSelected] = useState();
  
  useEffect(() => {
    const element = document.getElementById(currentCharacer);

    element && setSelected(element);
  }, []);

  selected?.scrollIntoView();

  return (
    <div className="main-blogs-page">
      {/* <Layout> */}
      <Header />
      <Banner />
      {/* Tab data */}
      <div className="tab-cover">
        <div className="tab">
          {alphabets.map((e) => {
            return (
              <h4
                className="letter"
                key={e}
                onClick={() => {
                  const element = document.getElementById(e);

                  element && (window.location = "/marketing-glossary/?alphabet=" + e);
                  //  setSelected([e])
                }}
              >
                {e}
              </h4>
            );
          })}
        </div>
      </div>
      {/* box data */}
      <ul className="tables">
        {alphabets.map((e) => (
          <GlossariesList letter={e} data={data} key={"table-" + e} />
        ))}
      </ul>
      {/* </Layout> */}
      <Footer/>
    </div>
  );
};

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { type: { eq: "glossary" } } }) {
      edges {
        node {
          frontmatter {
            id
            title
            name
            gPath
            date
            description
            thumbnail
            type
            link
          }
        }
      }
    }
  }
`;

export default Glossaries;
